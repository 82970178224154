import moment from 'moment';
import {FormikErrors} from 'formik';
import {BureauObj} from '../admin/Bureau';
import {ExaminateurRef} from '../admin/Utilisateur';
import {CommissariatRef} from '../global/Commissariat';
import {RequerantRef} from '../global/Requerant';
import {FichierObj} from '../Shared';
import {BordereauObj} from './Bordereau';
import {DPRapportEtChamps} from '../configuration/DPRapport';
import {readTypesDExamen} from '../configuration/Configuration';

export interface DossierPatientObj {
  id: number;
  créneauId?: number; // Pour DemandeDeVisite

  lieu: Lieu;

  dateRdvPris: string | null; // YYYY-MM-DD HH:mm
  dateArrivé: string | null; // YYYY-MM-DD HH:mm
  dateAnnulé: string | null; // YYYY-MM-DD HH:mm
  dateMail: string | null; // YYYY-MM-DD
  dateRemis: string | null; // YYYY-MM-DD

  statut: Statut;


  nom: string;
  profession: string;
  dateDeNaissance: string | null; // YYYY-MM-DD
  sexe: Sexe;
  commissariat: CommissariatRef | null;
  regie: string;


  rendezVousDate?: number; // Nombre de jours depuis 1970
  rendezVousHeure?: number; // Nombre de minutes depuis minuit
  examinateur: ExaminateurRef | null;
  examenType: MissionRef | null;
  examenConclusion: string; // = examenJoursITT if (RendezVousMedecin) || = ConclusionGAV enum if (DemandeDeVisite)
  examenDate: string | null; // YYYY-MM-DD
  examenHeure: string | null; // HH:mm

  gavHeureProlongation: string | null; // HH:mm
  gavVisiteEffectuée: boolean; // Lorsqu'on planifie une visite dans un commissariat à un médecin, ce champ vaut par défaut 'oui'. Si pour une raison ou une autre le médecin ne peut pas honorer le RDV, dans son espace médecin il a la possibilité via la liste des GAV du jour de refuser la visite et d'indiquer le motif de non visite qui est enregistré dans le champ ci-dessous.
  gavMotifNonVisite: MotifNonVisiteGAV;



  requerant: RequerantRef | null;
  requisitionFournie: boolean;
  requisitionDate: string | null; // YYYY-MM-DD
  requisitionNumero: string;

  attestationFournie: boolean;


  montantHT?: number;
  montantTVA: number;
  bordereauNumero: string;
  bordereau?: BordereauObj;


  photos?: FichierObj[];

  documents?: FichierObj[];


  affaireDate: string | null; // YYYY-MM-DD // TODO: Dans le fichier dossier.php ce champ est en display=none. Je ne vois pas comment on pourrait le renseigner dans la version actuelle. Je propose néanmoins de le reprendre au cas où.
  affaireNature: string; // TODO: Idem que affaireDate
  affaireNom: string; // TODO: Idem que affaireDate. Il est probable que la MAJ qui désactive l'usage de ces champs ait été faite vers Septembre 2021. Par ailleurs, même si on les reprend, je propose de ne pas les afficher comme actuellement.


  notes: string; // Observations
  retourExaminateur: string;

  bureau: BureauObj;

  dateModification: string; //YYYY-MM-DD HH:mm:ss

  verrou?: {
    nomUtilisateur: string;
    dateExpiration: Date
  };

}

export interface DossierPatientRef {
  id: number;
  nom: string; // Nom du patient
  examenType: MissionRef | null;
  statut: Statut;
}

interface DossierPatientFilterRef {
  id: number;
  nom: string; // Nom du patient
  examinateur: ExaminateurRef | null;
  commissariat: CommissariatRef | null;
  examenType: MissionRef | null;
  statut: Statut;
  notes: string; // Observations
}

export interface DossierPatientEtRapports extends DossierPatientObj {
  rapports: DPRapportEtChamps[];
}

export interface DossierPatientRapportsObj {
  id: number; // Identique au dossierPatient.id
  rapports: DPRapportEtChamps[];
}

export function getRendezVousDateHeure(objet: DossierPatientObj) {
  const date = objet.rendezVousDate ?? 0;
  const heure = objet.rendezVousHeure ?? 0;
  return date * 24 * 60 + heure;
};

export function getDateHeureExamen(objet: DossierPatientObj): Date {
  const date = objet.examenDate;
  const heure = objet.examenHeure;
  return moment(date + " " + heure, "DD/MM/YYYY HH:mm").toDate();
};

function isPositiveNumber(value?: string | null) { return !!value && (/^([1-9]\d*)$/.test(value) || value === '0'); }

function isDossierPatient(value: Statut | undefined) { return value !== undefined && value.id !== constStatuts.Aucun.id; } // Yup replaces '' with undefined

export function asValidDossierPatient(objet: DossierPatientRef) { // Note: There are "fake" DPs used to store custom disponibilité
  const dossierPatient = objet as DossierPatientObj;
  return dossierPatient.id > 0 && dossierPatient.statut?.id && dossierPatient.nom ? dossierPatient : null; // TODO: Proper check & validation?
};

export function prepareToSave(objet: DossierPatientObj, bureau: BureauObj) {
  if (objet.nom && (objet.statut === undefined || objet.statut.id === constStatuts.Aucun.id)) // Add undefined just in case, should never be needed // TODO: Warning?
    objet.statut = constStatuts.RdvPris;
  if (objet.statut.id >= constStatuts.RdvPris.id && !objet.dateRdvPris)
    objet.dateRdvPris = moment().format('YYYY-MM-DD HH:mm');
  const isCreating = objet.id <= 0;
  if (isCreating || !objet.bureau) // TODO: Feels a bit weird
    objet.bureau = bureau;
  return objet;
};

export function isValid(errors : FormikErrors<DossierPatientObj>) {
  return Object.keys(errors).every(key => errors[key as keyof FormikErrors<DossierPatientObj>] === undefined);
};

export function validate(values: DossierPatientObj, isEditModeExaminateur?: boolean, nouveauStatut?: Statut) {
  const errors : FormikErrors<DossierPatientObj> = {};
  if (!values.rendezVousDate)
    errors.rendezVousDate = 'Veuillez entrer une date';
  if (!values.rendezVousHeure && values.rendezVousHeure !== 0)
    errors.rendezVousHeure = 'Veuillez entrer une heure';
  if (!values.examinateur)
    errors.examinateur = 'Veuillez choisir l\'examinateur';

  if (isDossierPatient(values.statut) || !isEditModeExaminateur) {
    if (!values.nom)
      errors.nom = 'Veuillez entrer le nom du patient';

    if (values.statut.id >= constStatuts.Examiné.id && values.statut.id !== constStatuts.Annulé.id
      && (!nouveauStatut || (nouveauStatut.id >= constStatuts.Examiné.id && nouveauStatut.id !== constStatuts.Annulé.id))) { // Don't validate if we are moving backward
      if (!values.dateDeNaissance)
        errors.dateDeNaissance = 'Veuillez entrer une date de naissance';
      if (!values.sexe)
        errors.sexe = 'Veuillez choisir un sexe';
      if (!values.commissariat)
        errors.commissariat = 'Veuillez choisir une origine';
      if (!values.regie)
        errors.regie = 'Veuillez entrer une régie';

      if (!values.examenType)
        errors.examenType = "Veuillez choisir un type d'examen";
      if (!values.examenDate)
        errors.examenDate = 'Veuillez entrer une date';
      if (!values.examenHeure)
        errors.examenHeure = 'Veuillez entrer une heure';
      if (isMissionITT(values.examenType)) {
        if (!values.examenConclusion || !isPositiveNumber(values.examenConclusion))
          errors.examenConclusion = 'Veuillez entrer un nombre de jours';
      }
      if (isMissionGAV(values.examenType)) {
        if (values.gavVisiteEffectuée && !values.examenConclusion)
          errors.examenConclusion = 'Veuillez choisir une conclusion';
        if (!values.gavVisiteEffectuée && !values.gavMotifNonVisite)
          errors.gavMotifNonVisite = 'Veuillez choisir un motif';
      }

      if (values.requisitionFournie) {
        if (!values.requerant)
          errors.requerant = 'Veuillez choisir un requérant';
        if (!values.requisitionDate)
          errors.requisitionDate = 'Veuillez entrer une date de réquisition';
        if (!values.requisitionNumero)
          errors.requisitionNumero = 'Veuillez entrer un numéro de réquisition';

        if (!errors.requisitionDate && !!false) { // TODO: Do not require the doc for now
          const requisition = values.documents ? values.documents.filter(fichier => fichier.description === 'Réquisition') : undefined;
          if (!requisition || requisition.length !== 1)
            errors.requisitionDate = 'Veuillez attacher le document de la réquisition et préciser sa description';
        }
      }

      if (values.montantHT === undefined || values.montantHT < 0)
        errors.montantHT = 'Veuillez entrer un montant';
    }
  }
  return errors;
}

export function updateStatut(objet: DossierPatientRef, nouveauStatut: Statut) {
  let dossierPatient = asValidDossierPatient(objet);
  if (!dossierPatient) return null;
  dossierPatient = {...dossierPatient}; // Clone to avoid side-effects
  if (dossierPatient.statut.id === nouveauStatut.id) return null;
  if (nouveauStatut.id !== constStatuts.Annulé.id && !isValid(validate(dossierPatient, false, nouveauStatut))) {
    window.alert('Ce dossier patient est incomplet. Editez le pour ajouter des informations.');
    return null;
  }
  if (nouveauStatut.id === constStatuts.RapportValidé.id && !dossierPatient.requisitionFournie) {
    if (!window.confirm('Dans ce dossier patient, la case "Réquisition ?" n\'est pas coché.\n'
      + 'Ce dossier peut ne pas être complet.\n\n'
      + 'Êtes-vous sûr de vouloir valider ce dossier ?'))
      return null;
  }
  const now = moment();
  if (dossierPatient.statut.id > nouveauStatut.id) { // Going backward
    if (nouveauStatut.id < constStatuts.Arrivé.id && dossierPatient.dateArrivé)
      dossierPatient.dateArrivé = null;
    if (nouveauStatut.id < constStatuts.Examiné.id && dossierPatient.examenDate)
      dossierPatient.examenDate = null;
    if (nouveauStatut.id < constStatuts.Examiné.id && dossierPatient.examenHeure)
      dossierPatient.examenHeure = null;
    if (nouveauStatut.id < constStatuts.Annulé.id && dossierPatient.dateAnnulé)
      dossierPatient.dateAnnulé = null;
  } else if (nouveauStatut.id === constStatuts.Annulé.id) {
    if (!dossierPatient.dateAnnulé)
      dossierPatient.dateAnnulé = now.format('YYYY-MM-DD HH:mm');
  }
  else {
    if (nouveauStatut.id >= constStatuts.Arrivé.id && !dossierPatient.dateArrivé)
      dossierPatient.dateArrivé = now.format('YYYY-MM-DD HH:mm');
    if (nouveauStatut.id >= constStatuts.Examiné.id && !dossierPatient.examenDate)
      dossierPatient.examenDate = now.format('YYYY-MM-DD');
    if (nouveauStatut.id >= constStatuts.Examiné.id && !dossierPatient.examenHeure)
      dossierPatient.examenHeure = now.format('HH:mm');
  }
  dossierPatient.statut = nouveauStatut;
  return dossierPatient;
};

export enum Lieu {
  NonRenseigné = 0, // Can happen in dossiers patient coming from migration of previous system
  RendezVousMedecin = 10,
  RendezVousPsy = 20,
  DemandeDeVisite = 30,
}

export interface LieuRef {
  id: Lieu;
  nom: string;
}

export const constLieuList : LieuRef[] = [
  { id: Lieu.RendezVousMedecin, nom: 'Rendez-Vous ITT' },
  { id: Lieu.RendezVousPsy, nom: 'Rendez-Vous Psy' },
  { id: Lieu.DemandeDeVisite, nom: 'Demandes de Visite' },
];

export interface Statut {
  id: number;
  nom: string;
  alias?: string;
}

export const constStatuts = {
  Aucun: { id: 0, nom: '' } as Statut,
  RdvPris: { id: 10, nom: 'RDV pris', alias: 'Non vus' } as Statut,
  Arrivé: { id: 20, nom: 'Arrivé', alias: 'Visite à faire' } as Statut,
  Examiné: { id: 30, nom: 'Examiné', alias: 'Visite faite' } as Statut,
  RapportEnCours: { id: 40, nom: 'Rapport en cours' } as Statut,
  RapportValidé: { id: 50, nom: 'Rapport validé' } as Statut,
  Annulé: { id: 90, nom: 'Annulé' } as Statut,
}

const constStatutsMinMax = {
  ExaminéOuRapportX: { id: 101, nom: 'Examiné ou Rapport ...', min: constStatuts.Examiné, max: constStatuts.RapportValidé } as Statut,
}

export const constStatutEditableList = [constStatuts.RdvPris, constStatuts.Arrivé, constStatuts.Examiné, constStatuts.Annulé];

export const constStatutRapportEditableList = [constStatuts.Examiné, constStatuts.RapportEnCours, constStatuts.RapportValidé];

export const constStatutRechercheList = [constStatuts.RdvPris, constStatuts.Arrivé, constStatuts.Examiné, constStatuts.RapportEnCours, constStatuts.RapportValidé, constStatutsMinMax.ExaminéOuRapportX, constStatuts.Annulé];

export function getColorByStatut(statut: Statut) {
  switch (statut.id) {
    case constStatuts.Aucun.id: return undefined;
    case constStatuts.RdvPris.id: return 'black';
    case constStatuts.Arrivé.id: return 'darkgoldenrod';
    case constStatuts.Examiné.id: return 'blue';
    case constStatuts.RapportEnCours.id: return 'blueviolet';
    case constStatuts.RapportValidé.id: return 'green';
    case constStatuts.Annulé.id: return 'red';
    default: return undefined;
  }
};

export enum Sexe {
  Aucun = '',
  Homme = 'Homme',
  Femme = 'Femme',
}

export enum ConclusionGAV {
  Aucun = '',
  Compatible = 'Compatible',
  NonCompatible = 'Non compatible',
  Refus = 'Refus',
}

export enum MotifNonVisiteGAV {
  Aucun = '',
  Absent = 'Absent',
  Annulé = 'Annulé',
  MédecinSuivant = 'Médecin Suivant', // Can happen in dossiers patient coming from migration of previous system
}

export function getMotifNonVisiteGAVList(currentValue: MotifNonVisiteGAV) : MotifNonVisiteGAV[] {
  const list = Object.values(MotifNonVisiteGAV);
  if (currentValue !== MotifNonVisiteGAV.MédecinSuivant) // Kept only for migrated dossiers patient
    list.splice(list.indexOf(MotifNonVisiteGAV.MédecinSuivant), 1);
  return list;
}



export interface TypeExamen {
  id: number;
  nom: string;
  tarif: number;
  tva: boolean;
  notes: string;
}

export interface MissionRef {
  id: number;
  nom: string;
}

export const typeExamenDefaultList: TypeExamen[] = [
  { id: 20, nom: 'Autre', tarif: 10, tva: true, notes: 'Autre' },
  { id: 3, nom: 'DCD', tarif: 57.5, tva: true, notes: 'l' },
  { id: 16, nom: 'DCD+PS', tarif: 92, tva: true, notes: 'Examen du corps sans vie et prélèvement sanguin' },
  { id: 4, nom: 'DU', tarif: 40.5, tva: true, notes: 'Dépistage Urinaire (B150x0.27)' },
  { id: 10, nom: 'DU sans matériel', tarif: 13.5, tva: true, notes: 'DU B50' },
  { id: 8, nom: 'DU+PS', tarif: 75, tva: true, notes: 'Dépistage Urinaire (B150x0.27) + Prélèvement Sanguin' },
  { id: 13, nom: 'DU+PS+IN', tarif: 58.67, tva: true, notes: 'dépistage urinaire (B50) + Prélèvement sanguin + indemnité' },
  { id: 9, nom: 'EMP', tarif: 172.8, tva: true, notes: 'Examen médico-psychologique' },
  { id: 2, nom: 'GaV', tarif: 57.5, tva: false, notes: 'Examen de compatibilité avec la mesure de GAV' },
  { id: 6, nom: 'GAV+DU', tarif: 98, tva: false /*'nc'*/, notes: 'Examen de compatibilité avec la mesure de GAV + Dépistage Urinaire (B150x0.27)' },
  { id: 15, nom: 'GAV+DU+PS', tarif: 132.5, tva: false /*'nc'*/, notes: 'Examen de compatibilité avec la mesure de GAV + dépistage urinaire (B150x0.27) + prélèvement sanguin' },
  { id: 17, nom: 'GAV+ITT', tarif: 0, tva: false /*'nc'*/, notes: 'Examen de compatibilité avec la mesure de GAV + Examen descriptif des lésions' },
  { id: 7, nom: 'GAV+PS', tarif: 92, tva: false /*'nc'*/, notes: 'Examen de compatibilité avec la mesure de GAV + Prélèvement Sanguin' },
  { id: 21, nom: 'GAV+TVA', tarif: 57.5, tva: true, notes: 'GAV+TVA' },
  { id: 11, nom: 'IN', tarif: 10.67, tva: true, notes: 'Indemnité entre 22h et 7h' },
  { id: 24, nom: 'IPM', tarif: 140, tva: false, notes: 'Ivresse Publique Manifeste' },
  { id: 25, nom: 'IPMp', tarif: 180, tva: false, notes: 'Ivresse Publique Manifeste + indemnité' },
  { id: 1, nom: 'ITT', tarif: 80.5, tva: true, notes: 'Examen descriptif des lésions et établissement d\'une ITT' },
  { id: 23, nom: 'ITT sans TVA', tarif: 80.5, tva: false, notes: '' },
  { id: 19, nom: 'ITT+GAV', tarif: 0, tva: false /*'nc'*/, notes: '' },
  { id: 14, nom: 'K90', tarif: 370.5, tva: true, notes: 'Expertise Psychologique' },
  { id: 18, nom: 'PGAV', tarif: 46, tva: false, notes: 'Examen de compatibilité avec la mesure de prolongation de GAV' },
  { id: 22, nom: 'PGAV+TVA', tarif: 46, tva: true, notes: '' },
  { id: 5, nom: 'PS', tarif: 34.5, tva: true, notes: 'Prélèvement Sanguin' },
  { id: 12, nom: 'PS+IN', tarif: 45.2, tva: true, notes: 'Prélèvement sanguin + indemnité' },
];

export function newTypeExamen() : TypeExamen {
  return {
    id: 0,
    nom: '',
    tarif: 0,
    tva: true,
    notes: '',
  };
}

export function getTypeExamen(missionRef?: MissionRef | null) : { tarif: number, tva: boolean } | undefined {
  if (!missionRef) return undefined;
  const typeExamen = readTypesDExamen().find(m => m.id === missionRef.id);
  if (!typeExamen) return undefined; // TODO: Warning? Should be impossible
  return typeExamen;
};

export const constMissionITT = readTypesDExamen().find(m => m.id === 1 || m.nom === 'ITT')!;
export const constMissionK90 = readTypesDExamen().find(m => m.id === 14 || m.nom === 'K90')!;
export const constMissionGAV = readTypesDExamen().find(m => m.id === 2 || m.nom === 'GaV')!;

export const isMissionITT = (mission?: MissionRef | null) : boolean => {
  return mission ? mission.nom === 'ITT' : false; // TODO: Switch to id === 1 ???
};

export const isMissionLikeITT = (mission?: MissionRef | null) : boolean => {
  return mission && mission.nom ? mission.nom.toUpperCase().includes('ITT') : false;
};

export const isMissionGAV = (mission?: MissionRef | null) : boolean => {
  return mission ? mission.nom === 'GaV' : false; // TODO: Switch to id === 2 ???
};

export const isMissionPGAV = (mission?: MissionRef | null) : boolean => {
  return mission ? mission.nom === 'PGAV' : false; // TODO: Switch to id === 18 ???
};

export const isMissionIPMx = (mission?: MissionRef | null) : boolean => {
  return mission ? mission.nom === 'IPM' || mission.nom === 'IPMp' : false; // TODO: Switch to id === 24/25 ???
};

export const getExamenConclusion = (objet: DossierPatientRef) : string => {
  const data = asValidDossierPatient(objet);
  if (!data) return '';
  const conclusionExamenGenerique = !data.gavVisiteEffectuée && data.gavMotifNonVisite ? (' (' + data.gavMotifNonVisite + ')') : '';
  if (isMissionITT(data.examenType))
    return !data.gavVisiteEffectuée && data.gavMotifNonVisite ? (' (' + data.gavMotifNonVisite + ')')
    : data.examenConclusion ? (' (' + data.examenConclusion + ' jour' + (parseInt(data.examenConclusion, 10) < 2 ? '' : 's') + ')') : '';
  if (isMissionGAV(data.examenType))
    return !data.gavVisiteEffectuée && data.gavMotifNonVisite ? (' (' + data.gavMotifNonVisite + ')')
      : data.examenConclusion ? (' (' + data.examenConclusion + ')') : '';
  if (isMissionPGAV(data.examenType))
    return !data.gavVisiteEffectuée && data.gavMotifNonVisite ? (' (' + data.gavMotifNonVisite + ')')
    : data.gavHeureProlongation ? (' (' + data.gavHeureProlongation + ')') : '';
  if (isMissionIPMx(data.examenType))
    return !data.gavVisiteEffectuée && data.gavMotifNonVisite ? (' (' + data.gavMotifNonVisite + ')')
    : data.examenConclusion ? (' (' + data.examenConclusion + ')') : '';
  return conclusionExamenGenerique;
};



export function filter<T extends DossierPatientFilterRef>(objets: T[], search: string) {
  if (search) {
    search = search.toUpperCase();
    const requisitionFournie = search.includes('RÉQUISITION') || search.includes('REQUISITION');
    if (requisitionFournie) // Remove it
      search = search.replaceAll('RÉQUISITION', ' ').replaceAll('REQUISITION', ' ');
    search = search.trim();

    objets = objets.filter(
      objet =>
        (
          !search ||
          (objet.nom.toUpperCase().includes(search) ||
          objet.examenType?.nom.toUpperCase().includes(search) ||
          objet.commissariat?.nom.toUpperCase().includes(search) ||
          objet.examinateur?.nomComplet.toUpperCase().includes(search) ||
          objet.statut.nom.toUpperCase().includes(search) ||
          objet.notes.toUpperCase().includes(search))
        ) &&
        (!requisitionFournie || !!(objet as any).requisitionFournie)
    );
  }
  return objets;
};



export function newDossierPatient(): DossierPatientObj { // Note: Mock object; only visible if canCreate
  return {
    id: 0,

    lieu: Lieu.RendezVousMedecin,

    dateRdvPris: '2022-04-29 16:00',
    dateArrivé: null,
    dateAnnulé: null,
    dateMail: '2021-12-30',
    dateRemis: '2021-12-31',
    statut: constStatuts.RdvPris, // constStatuts.RapportValidé,

    nom: 'TOURE Mamadou (Mock)',
    profession: 'Informaticien',
    dateDeNaissance: '1989-12-21',
    sexe: Sexe.Homme,
    commissariat: null, // {id: 'Saint-Denis', nom: 'Saint-Denis', regie: '93'},
    regie: '', // '93',

    rendezVousDate: 19111, // 2022-04-29
    rendezVousHeure: 8 * 60 + 15,
    examinateur: null,
    examenType: constMissionITT,
    examenConclusion: '5',
    examenDate: null,
    examenHeure: null,

    gavHeureProlongation: null,
    gavVisiteEffectuée: true,
    gavMotifNonVisite: MotifNonVisiteGAV.Aucun,

    requerant: null,
    requisitionFournie: true,
    requisitionDate: '2022-04-30',
    requisitionNumero: '2022/1234/R',

    attestationFournie: false,

    montantHT: 80.5,
    montantTVA: 20,
    bordereauNumero: '',
    dateModification: '2022-07-27 17:08:55',

    photos: [
      {
        nom: 'Nebulizerpipe.JPG',
        description: 'Nébuliseur',
        typeMime: 'image/jpeg',
        tailleEnOctets: 1024 * 1024,
        dateModification: 1405553506000,
        originalUrl: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Nebulizerpipe.JPG',
        originalW: null,
        originalH: null,
        apercu320Url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Nebulizerpipe.JPG/229px-Nebulizerpipe.JPG',
        apercu320W: 128,
        apercu320H: 128,
        apercu320Data: undefined,
        originalData: undefined
      },
      {
        nom: 'Poison_ivy_rash.JPG',
        description: 'Éruption cutanée due à l’herbe à puce',
        typeMime: 'image/jpeg',
        tailleEnOctets: 1024 * 1024,
        dateModification: 1405553506000,
        originalUrl: 'https://upload.wikimedia.org/wikipedia/en/c/c8/Poison_ivy_rash.JPG',
        originalW: null,
        originalH: null,
        apercu320Url: 'https://upload.wikimedia.org/wikipedia/en/thumb/c/c8/Poison_ivy_rash.JPG/180px-Poison_ivy_rash.JPG',
        apercu320W: 128,
        apercu320H: 128,
        apercu320Data: undefined,
        originalData: undefined
      },
      {
        nom: 'Man_in_supine_position.jpg',
        description: '',
        typeMime: 'image/jpeg',
        tailleEnOctets: 1024 * 1024,
        dateModification: 1405553506000,
        originalUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/1f/Man_in_supine_position.jpg',
        originalW: null,
        originalH: null,
        apercu320Url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Man_in_supine_position.jpg/320px-Man_in_supine_position.jpg',
        apercu320W: 128,
        apercu320H: 128,
        apercu320Data: undefined,
        originalData: undefined
      },
      {
        nom: 'Health_pictogram.svg',
        description: '',
        typeMime: 'image/svg+xml',
        tailleEnOctets: 4 * 1024,
        dateModification: 1405553506000,
        originalUrl: 'https://upload.wikimedia.org/wikipedia/commons/8/8d/Health_pictogram.svg',
        originalW: null,
        originalH: null,
        apercu320Url: 'https://upload.wikimedia.org/wikipedia/commons/8/8d/Health_pictogram.svg',
        apercu320W: 128,
        apercu320H: 128,
        apercu320Data: undefined,
        originalData: undefined
      },
      /*{
        nom: 'asantha.jpg',
        description: '',
        typeMime: 'image/jpeg',
        tailleEnOctets: 1024 * 1024,
        dateModification: 1405553506000,
        originalUrl: 'https://cra-ts.cremawork.com/assets/images/avatar/A5.jpg',
        originalW: null,
        originalH: null,
        apercu320Url: 'https://cra-ts.cremawork.com/assets/images/avatar/A4.jpg',
        apercu320W: 128,
        apercu320H: 128,
      },
      {
        nom: '',
        description: '',
        typeMime: '',
        tailleEnOctets: 0,
        dateModification: 0,
        originalUrl: '',
        originalW: null,
        originalH: null,
        apercu320Url: '',
        apercu320W: 0,
        apercu320H: 0,
      },
      {
        nom: 'Lorèm ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        description: 'Lorèm ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        typeMime: 'image/jpeg',
        tailleEnOctets: -1234567890.5,
        dateModification: 1640991599000,
        originalUrl: 'https://cra-ts.cremawork.com/assets/images/avatar/A7.jpg',
        originalW: null,
        originalH: null,
        apercu320Url: 'https://cra-ts.cremawork.com/assets/images/avatar/A8.jpg',
        apercu320W: 128,
        apercu320H: 128,
      },/**/
    ],

    documents: [
      {
        nom: 'PDF_file_icon.svg',
        description: 'Réquisition',
        typeMime: 'application/pdf',
        tailleEnOctets: 4 * 1024,
        dateModification: 1405553506000,
        originalUrl: 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg',
        originalW: null,
        originalH: null,
        apercu320Url: 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg',
        apercu320W: 128,
        apercu320H: 128,
        apercu320Data: undefined,
        originalData: undefined
      },
    ],

    affaireDate: null,
    affaireNature: '',
    affaireNom: '',

    notes: "CEEA, rébellion, outrage, délit de fuite, menaces de mort sur personne dépositaire de l'autorité publique", // Observations
    retourExaminateur: '',

    bureau: null!, // Let prepareToSave() set it // TODO: OK?
  };
}








export function toDateString(date?: string | null, prefix?: string | null) {
  return date ? (prefix ?? '') + moment(date).format('L') : '';
}
export function toDateTimeString(date?: string | null, prefix?: string | null) {
  return date ? (prefix ?? '') + moment(date).format('L à LT') : '';
}

export function toDateTime(date?: Date | null, prefix?: string | null) {
  return date ? (prefix ?? '') + moment(date).format('L à LT') : '';
}